import dynamic from 'next/dynamic';
import Skeleton from 'react-loading-skeleton';
import { SectionsKey } from '~/constants/enum';
import { ProductDetailTwoLoading } from '~/sections/ProductDetailTwo';
import { getTheme } from './theme';
import BrandSkeleton from '~/sections/Brand/Brand.skeleton';

export const getPageStruct = (themeName = getTheme(), fileName = '') => {
    if (!fileName) return null;
    try {
        const PageStruct = require(`~/theme/${themeName}/structure/${fileName}.struct`);
        return PageStruct.default;
    } catch (error) {
        const PageStruct = require(`~/theme/base/structure/${fileName}.struct`);
        return PageStruct.default;
    }
};

export const renderSectionNew = (item: any) => {
    switch (item.id) {
        case SectionsKey.Hero:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/Hero`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/Hero`).then((mod) => mod.default);
                        }),
                {
                    ssr: false,
                    loading: () => <Skeleton className={'w-full h-[75vh]'} />
                }
            );
        case SectionsKey.Brand:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/Brand`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/Brand`).then((mod) => mod.default);
                        }),
                {
                    // cmsBlock
                    ssr: false,
                    loading: () => <BrandSkeleton />
                }
            );
        case SectionsKey.ProductMatching:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/ProductMatching`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/ProductMatching`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.RecentlyView:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/RecentlyView`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/RecentlyView`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.TopCategories:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/TopCategories`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/TopCategory`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.BestSeller:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/BestSeller`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/BestSeller`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.MostPopular:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/MostPopular`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/MostPopular`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.FeatureProduct:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/FeatureProduct`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/FeaturedProducts`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.NewCollections:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/NewCollections`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/NewCollection`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.Trending:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/Trending`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/Trending`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.Recommended:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/Recommended`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/Recommended`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );

        case SectionsKey.ProductDetailTwo:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/ProductDetailTwo`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/ProductDetailTwo`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.ProductList:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/ProductList`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/ProductList`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.Related:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/Related`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/Related`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.ProductTags:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/ProductTags`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/ProductTags`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.Cart:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/Cart`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/Cart`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.NotFound:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/PageNotFound`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/PageNotFound`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => <div>Loading NotFound...</div>
                }
            );
        case SectionsKey.TestimonialVer2:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/TestimonialVer2`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/TestimonialVer2`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        case SectionsKey.CollectionsContent:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/CollectionsContent`)
                        .then((mod) => mod.default)
                        .catch((error) => {
                            return import(`~/sections/CollectionsContent`).then((mod) => mod.default);
                        }),
                {
                    ssr: true,
                    loading: () => null
                }
            );
        default:
            return null;
    }
};
