import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Container from '~/components/Container/Container';

const BrandSkeleton = () => {
    return (
        <Container>
            <div className="flex gap-2 lg:gap-6 flex-col lg:flex-row">
                <div className=" aspect-square flex-1">
                    <Skeleton className="w-full h-full" />
                </div>
                <div className="w-full aspect-square flex flex-col gap-2 lg:gap-6 flex-1">
                    <div className="flex-1 ">
                        <Skeleton className="w-full h-full" />
                    </div>
                    <div className="flex-1">
                        <Skeleton className="w-full h-full" />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default BrandSkeleton;
