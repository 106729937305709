import { Identifiers, SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'home',
    useLayout: true,
    children: [
        { id: SectionsKey.Hero, order: 1 },
        { id: SectionsKey.Brand, order: 2, identifier: Identifiers.HomeBrand },

        { id: SectionsKey.ProductMatching, order: 3, identifier: Identifiers.ProductMatching },
        { id: SectionsKey.RecentlyView, order: 4 },

        { id: SectionsKey.TopCategories, order: 5, identifier: Identifiers.TopCategories },

        { id: SectionsKey.BestSeller, order: 6 },
        { id: SectionsKey.MostPopular, order: 7 },

        { id: SectionsKey.FeatureProduct, order: 8, identifier: Identifiers.FeatureProduct },

        { id: SectionsKey.NewCollections, order: 9, identifier: Identifiers.NewCollection },
        { id: SectionsKey.Trending, order: 10 },
        {
            id: SectionsKey.TestimonialVer2,
            order: 11,
            dataKey: SectionsKey.TestimonialVer2
        },
        { id: SectionsKey.Recommended, order: 12, identifier: Identifiers.Recommended }
    ]
};

export default PageStruct;
